<template>
  <div>    
    <section>
      <v-card class="mt-5">
        <v-card-subtitle>Audience from Master Data</v-card-subtitle>
        <v-card-text>
          <v-text-field
              class="ml-5"
              v-model="audienceLoad.name"
              dense
              outlined
              label="Audience B Name"
              required />
          <div class="pa-4">
          <v-chip-group
            v-if="!GetAudienceList.length"
            active-class="primary--text"
            column>
            <v-chip
              v-for="tag in GetAudienceList.audiences"
              :key="tag"
              close
              @click:close="deleteSubAudience(tag.name)">
              {{ tag.name }}
            </v-chip>
          </v-chip-group>
        </div>
          <div class="mt-5 d-flex mb-6">
            <div class="file-wrapper"
              @dragover.prevent
              @drop.prevent>
              <div @drop="handleFileDrop">
                <input type="file"
                  name="primary-file-input"
                  @change="onChangePrimaryFile" />
              </div>
              <div class="d-flex">
                <v-icon class="align-self-center"
                  size="50px">mdi-file-table-outline</v-icon>
                <p class="align-self-center">Drag audience B file here or click to upload.</p>
                <ul 
                  class="mt-6 ml-6">
                  <li v-for="(file, index) in audienceLoad.primaryFile"
                    :key="index"
                    class="fileList">
                    {{ file.name }} ({{ file.size / 1000 }} Kb.) 
                    <button @click="removeFilePrimary(index)"
                      title="Remove">X</button>
                  </li>
                </ul>
              </div>
              <v-progress-linear
                v-show="GetLoading === true"
                indeterminate
                color="cyan"></v-progress-linear>
            </div>

            
          </div>
          <div v-for="(condition, index) in masterData"
            :key="index">
            <div
              class="hr-sect" 
              v-if="index > 0">AND
            </div>
            <div class="d-flex justify-space-between">
              <v-col>
                <v-combobox
                  v-model="condition.key"
                  dense
                  hide-selected
                  :items="GetDataAudienceMaster.master.headers"
                  label="field"
                  outlined />
              </v-col>
              <v-col>
                <v-combobox
                  v-model="condition.operator"
                  dense
                  :items="conditions"
                  item-text="keys"
                  item-value="value"
                  label="Conditon"
                  outlined />
              </v-col>
              <v-col>
                <v-text-field
                  v-model="condition.value"
                  label=""
                  dense
                  outlined
                  required />
              </v-col>
              <v-col>
                <v-btn
                  class="mr-5"
                  outlined
                  color="indigo"
                  @click="addCondition()">AND</v-btn>
                <v-btn
                  icon
                  @click="removeCondition(index)">
                  <v-icon>mdi-trash-can-outline</v-icon>
                </v-btn>
              </v-col>
            </div>
          </div>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <h2>Total Estimate Audience Master : <strong>{{totalMasterEstimate}}</strong></h2>
          <v-spacer />
          <v-btn 
            color="red"
            @click="clearEstimate"
            outlined> Reset Estimate </v-btn>
          <v-btn 
            color="primary"
            @click="getEstimateMaster"
            outlined> Estimate Audience Master </v-btn>
        </v-card-actions>
      </v-card>
      
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'customAudince',
  props: {
    audienceData: {
      type: Object
    }
  },
  data () {
    return {
      loadingPrimaryFile: false,
      loadingValuePrimaryFile: 0,
      delSubAudience: {
        name: ''
      },
      // primaryData: [],
      primaryHeader: [],
      masterHeader: [],
      masterData: [{
        key: '',
        operator: '',
        value: ''
      }],
      audienceLoad: {
        name: '',
        primaryFile: [],
      },
      fieldType: [
        {
          keys: 'String',
          value: 'string'
        },
        {
          keys: 'Number',
          value: 'number'
        },
        {
          keys: 'Date',
          value: 'Date'
        }
      ],
      conditions: [
        {
          keys: 'Equal ( = )',
          value: 'equal'
        },
        {
          keys: 'Greater Than ( > )',
          value: 'greater than'
        },

        {
          keys: 'Greater Than Or Equal ( >= )',
          value: 'greater than or equal'
        },

        {
          keys: 'Less Than ( < )',
          value: 'less than'
        },

        {
          keys: 'Less Than Or Equal ( <= )',
          value: 'less than or equal'
        },

        {
          keys: 'Not Equal ( != )',
          value: 'not equal'
        },
      ],
      audience:
      {
        key: '',
        operator: '',
        value: ''
      },
      totalEstimate: 0,
      totalMasterEstimate: 0,
      totalPoEstimate: 0,
    }
  },

  async created() {
    await this.getDataMaster();
    await this.getAudiences();
  },

  computed: {
    ...mapGetters({
      GetDataAudienceMaster: 'audiences/GET_MASTER_HEADER',
      GetLoading: 'audiences/GET_UPLOAD_AUDINCE_STATUS',
      GetAudienceList: 'audiences/GET_AUDINCE',
      GetAudienceEstimate: 'audiences/GET_ESTIMATE_AUDINCE_MASTER',
    })
  },

  watch: {
    GetAudienceEstimate (val) {
      this.totalMasterEstimate = val.count.toLocaleString();
    }
  },

  methods: {
    async getDataMaster() {
      await this.$store.dispatch('audiences/GetAudinceMasterHeader');
    },

    async getAudiences() {
      await this.$store.dispatch('audiences/GetAudince');
    },

    async getEstimateMaster() {
      this.audienceData.conditions = [];
      this.masterData.forEach(a => {
        if (a.key !== '') {
          this.audienceData.conditions.push({
            key: a.key,
            operator: a.operator.value,
            value: a.value
          });
        }

        return this.audienceData;
      })

      await this.$store.dispatch('audiences/EstimateAudinceMaster', this.audienceData)
    },

    async onChangePrimaryFile(event) {
      const input = event.target;
      if (!event.target.files) return;

      const fd = new FormData();
      ([...input.files]).forEach((f) => {
        this.audienceLoad.primaryFile.push(f);
        fd.append('file', f)
        fd.append('name', this.audienceLoad.name)
      });
      await this.$store.dispatch('audiences/AddCustomAudience', fd);
    },    

    handleFileDrop(e) {
      const droppedFiles = e.dataTransfer.files;

      // eslint-disable-next-line no-useless-return
      if (!droppedFiles) return;

      const fd = new FormData();
      ([...droppedFiles]).forEach(f => {
        this.audienceLoad.primaryFile.push(f);
        fd.append('file', f)
      })
    },

    removeFilePrimary(fileKey){
      this.audienceLoad.primaryFile.splice(fileKey, 1);
      // this.primaryFileData = [];
      // this.primaryHeader = []
    },

    addCondition() {
      this.masterData.push({
        key: '',
        operator: '',
        value: ''
      });
    },

    addSecondaryCondition() {
      this.audiencePO.push({
        poField: '',
        type: '',
        condition: '',
        value: ''
      });

      this.filterAudience();
    },

    removeCondition(index) {
      this.masterData.splice(index, 1);
    },

    async deleteSubAudience(name) {
      await this.$store.dispatch('audiences/DeleteSubAudience', name)
    },

    clearEstimate() {
      this.audienceData.conditions = [];
      this.masterData = [{
        key: '',
        operator: '',
        value: ''
      }];
    }
  }
}
</script>

<style lang="scss" scoped>
  .v-progress-circular {
    margin: 0.5rem;
  }

  .file-wrapper {
    text-align: center;
    margin: auto auto;
    border-style: dashed;
    border-width: 1px;
    border-radius: 5px;
    min-width: 350px;
    height: 60px;
    vertical-align: middle;
    display: block;
    position: relative;
    overflow: hidden; 
      input {
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        opacity: 0.0;
        filter: alpha(opacity=0);
        font-size: 300px;
        height: 100px;
      }

      ul {
        position: absolute;
      }
  }

  .fileList {
    color: #039BE5;
    list-style-type: none;
  }

  .hr-sect {
    display: flex;
    flex-basis: 100%;
    align-items: center;
    color: rgba(0, 0, 0, 0.35);
    margin: 8px 0;

    &:before, &:after {
        content: "";
        flex-grow: 1;
        background: rgba(0, 0, 0, 0.35);
        height: 1px;
        font-size: 0;
        line-height: 0;
        margin: 0 8px;
    }
}
</style>